@keyframes disappear {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#hoverChangeTick:hover{
  background-color: #c7dbff17;
}
#hoverChangeCross:hover{
  background-color: #c7dbff17;
}

