.no-data-cls {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 14px;
  padding: 5px;
}

.no-data-table-cls {
  td, td:last-child, td:first-child {
    text-align: center;
  }
}