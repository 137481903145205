.secondary-btn {
  width: 5rem;
  height: 32px;
  color: rgb(218, 24, 132) !important;
  border: solid rgb(218, 24, 132) 1px !important;
  font-size: 16px !important;

  &.padding-paste-add{
    width: 10rem;
  }

  &:hover{
    background: rgba(0, 0, 0, 0.13) !important;
  }
}
