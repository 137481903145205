@import '../../mixins.scss';

.report-handler-cls {
  width: 297mm;
  margin: 0 auto;
  background: white;
}

.spl-btn-cls {
  position: fixed;
  left: 20px;
  top: 170px;
  display: flex;
  flex-direction: column;
  z-index: 1001;

  .toggle-btn-cls {
    &.exclude-print {
      @include fab-buttons;
    }
  }

  .pagebreak-btn-cls {
      @include fab-buttons;
  }

  .selection-btn-cls {
    &.exclude-print {
      @include fab-buttons;
    }
  }

  .save-te-btn-cls {
    &.exclude-print {
      @include fab-buttons;
    }
  }
 
  .submit-cr-btn-cls {
    &.exclude-print {
      @include fab-buttons;
    }

    &.mpe-icon-common-style {
      border-color: lightgray ;
      border: 1px solid transparent;
      color: white;
    }

    &.mpe-icon-common-style:hover {
      color: white
    }

    &.dossier-in-contributor-review-inprogress {
      background-color: #0B78B8;
    }

    &.dossier-in-techEvl-contributor-review-done {
      background-color: #FF7700;
    }

    &.dossier-in-review-contributor-review-done {
      background-color: #08875B;
    }

    &.contributor-review-icon-disabled {
      opacity: 0.5;
    }
    
  }


  .revoke-cr-btn-cls{
    &.exclude-print {
      @include fab-buttons;
    }
   }

}