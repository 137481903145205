.action-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-left: 33%;

  button{
    width: 100%;
  }
}
