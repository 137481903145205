@import "../../variable.scss";

.dossier-cards-cls {
  margin: 5px;
  min-height: 200px;

  &.custom-padding {
    padding: 5px 5px 0 5px;
  }

  .card-header {
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 5px;
    flex-wrap: wrap;
    padding: 5px 0;

    div{
      margin-right: 0;
    }

    .header-content {
      padding: 0;
      font-size: 0.7em;

      &::after {
        display: none;
      }
    }
    span {
      margin-left: 10px;
    }
  }

  .card-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 140px;

    .card-main {
      margin-bottom: 5px;
      overflow: hidden;
      word-break: break-word;
      min-height: 120px;

      p {
        margin: 5px 0;
      }

      .red {
        background: red;
        color: #fff;
      }

      .green {
        background: green;
        color: #fff;
      }

      .dossier-stats-header {
        .header-content {
          padding: 0;
          .icon {
            border-radius: 50%;
          }
        }
      }

      .dossier-stats {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 5px 0;
        padding: 0 0 5px 0;

        .dossier-stats-data {
          display: flex;
          flex-flow: column;
          align-items: center;
          width: 50%;

          span:nth-child(1) {
            height: 40px;
            align-items: center;
            display: flex;
          }

          span:nth-child(2) {
            border: 1px solid $section-heading;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
          }
        }
      }
    }
  }

  .card-footer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
