.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1rem 0;
}

.label {
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  flex: 1;
}

.input {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
