@import "../../variable.scss";
.essay-cls {
  padding: 0 25px;

  .heading {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
    display: flex;

    &.last {
      page-break-before: always;
    }
  }

  .paragraph {
    &.grayedParagraph {
      opacity: 0.7;
      background-color: $disabled-gray
    }
    text-align: left;
    white-space: pre-wrap;
    padding: 0 0 0 25px;
    position: relative;

    p {
      min-height: 1em;
    }
    img {
      max-width: 100%;
    }
  }

  .flex-container {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }

  .para-edit-info {
    font-style: italic;
    font-size: medium;
    color: gray;
  }

  .edit-btn,.preview-btn,.restore-btn {
    color: $section-heading;
  }

  .toggle-style{
    padding: 6px;
  }
}
