.avatar-initials-tooltip-cls {
  text-transform: capitalize;

}

.avatar-initials-cls {
  border-radius: 50%;
  background: #8390a7;
  width: 25px;
  height: 25px;
  text-align: center;
  color: white;
  line-height: 2em;
  font-size: 0.5em;
}