.notification-cls {
  width: 100%;
  box-shadow: 0 10px 0 #f8f9fb;
  background: #f8f9fb;
  overflow: hidden;
  text-align: left;
  .notification-position-cls{
    position: fixed;
    top:0;
    z-index: 1102;
    width: 100%;
    min-height: 60px;
  }

  .progress-bar{
     div:first-child {
        color: #ffffff;
      }
  }
}

.syncStatus {
  display: flex;
  align-items: center;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
