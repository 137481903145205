 .contributo-comments {
    text-align: left;
    font-size: 0.875rem;
    white-space: pre-wrap;
    padding: 10px 10px;
    margin: 0 0 12px 0;
    background-color: #f7f9fa;

   .comment-card {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    padding-bottom: 0;
  }

  .avatar-icon {
    height: 1.7rem;
    font-size: 1.5rem;
    margin-right: 1rem;
	}

  .comment-desc {
    font-size: 0.875rem;
    margin-bottom: 0;
    width: 97%;
    overflow-wrap: break-word;
  }

  .comment-desc-nocomment {
    width : 100%;
  }

  .comment-date {
    float: right;
  }

  .comment-date-nocomment {
    float: right;
  }
  

 }
 
 .bg-nocomment {
  background-color: lightyellow;
  padding: 0px 0px;
 }