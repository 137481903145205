@import '../../variable.scss';
@import '../../mixins.scss';
.chart-cls {
  @include sarge-content;
  .chart-title {
    background: $table-header;
    color: $title-color;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 16px;
    padding: 2px;
    text-align: left;
    border-bottom: 1px solid $heading-border;
    white-space: pre-wrap;
    display: flex;
    justify-content: space-between;
  }
  .chart-style {
    width: 100%;
  }
  :fullscreen, ::backdrop {
    background-color: white;
  }
  .full-screen-icon {
    cursor: pointer;
    font-size: 20px;
  }
}
