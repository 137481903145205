.file-attachments-cls {
  padding: 0 2px;
  min-height: calc(210mm - 200px);
  .pointer {
    cursor: pointer;
    text-decoration: underline;
    color: -webkit-link;
  }

  @media print {
    .pointer {
      cursor: auto;
      text-decoration: none;
      color: initial;
    }
  }

  .attachments-table {
    table {
      tr {
        min-height: 56px;

        td {
          min-width: 80px;
          padding: 2px;
          padding-left:5px;

          &:nth-child(1) {
            width: 120px;
          }

          &:nth-child(2) {
            font-weight: bold;
            padding-right: 4px;
            min-width: 150px;
            width: 150px;
          }

          &:nth-child(3) {
            font-style: italic;
            text-align: left;
          }

          &:nth-child(2) {
            font-weight: bold;
          }

          &.icon-cls {
            width: 48px;
            min-width: 48px;
            text-align: right;
            @media print {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
