.dialog-main {
  &.selector-cls{
    min-height: 200px;
    max-height: 590px;
    max-width: 55rem;
    .dialog-header {
      font-size: medium;
      font-weight: bold;
      padding: 10px 15px;
    }
  }

}
