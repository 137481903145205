.attachment-card-cls {
  &::before {
    content: '';
    height: 10px;
    background: #eaeaeb;
    display: block;
  }
  .card-header {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .heading {
      background: #647592;
      width: 100%;
      text-align: left;
      padding: 2px 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        padding: 0 5px;
      }
    }
    .upload-btn {
      padding: 2px 20px;
      border-radius: 0;
    }
  }
  .attachments-table {
    .table-body {
      tr {
        min-height: 40px;
        td {
          min-width: 80px;
          padding: 2px;

          &:nth-child(1) {
            width: 120px;
          }

          &:nth-child(2) {
            font-weight: bold;
            min-width: 150px;
            width: 150px;
          }

          &:nth-child(3) {
            font-style: italic;
            text-align: left;
          }

          &.action-cell {
            max-width: 80px;
            width: 80px;
            text-align: right;
          }
        }
      }
    }

  }
  .card-list {
    li {
      padding: 5px;
      div {
        &:first-child {
          margin: 0 5px 0 0;
        }
        &:last-child {
          flex: 0 1 auto;
        }
      }
    }
  }
  .uppy-DragDrop-container{
    padding: 20px;
  }
}
.uppy-DragDrop-inner{
  padding: 0 !important;
}

.upload-dialog-container{
  min-width: 500px;
  min-height: 50%;
}

.confirm-dialog-container{
  min-width: 300px;
  min-height: 30%;
}
