$table-header: #f0f4f6;
$default: #000000;
$title-color: #011133;
$heading-border: #a2abcd;
$white: #fff;
$table-row-alt: #f7f9fa;
$section-heading: #da1884;
$section-heading-secondary: #00205b;
$section-heading-tertiary: #00aec7;
$disabled-gray : #c2c2c2;
$hover-section-heading : #b6166e
