@import "../../../../../frontend/src/variable.scss";

.title-div {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: $section-heading;
  color: $white;
  padding: 0.5rem 1rem;
  font-family: "Roboto Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 30px;
}

.title-container{
  display: flex;
  justify-content: space-between;
}

.dropdown-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.banner-component-reliability {
  z-index: 0 !important;
}

.no-data{
  text-align: center;
  padding: 4rem;
}
