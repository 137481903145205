@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin separator {
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: $logo-green;
    padding: 0 0 16px 0;
  }

  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid $line-bg;
  }

  .separator::before {
    margin-right: .3em;
  }

  .separator::after {
    margin-left: .3em;
  }
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('fonts/Roboto-Medium.ttf') format('truetype');

}

@font-face {
  font-family: 'Roboto Regular';
  src: url('fonts/Roboto-Regular.ttf') format('truetype');

}

@mixin sarge-content {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  padding: 0 !important;
}

@mixin fab-buttons{
  margin: 10px 0;
  padding: 0px;
  line-height: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: rgb(236, 236, 236);
  border-color: #e4e4e4;
  box-shadow: 0px 3px 5px -1px rgba(83, 79, 79, 0.2),
    0px 6px 10px 0px rgb(83, 79, 79), 0px 1px 18px 0px rgb(83, 79, 79);
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgb(213, 213, 213);
  }
}
