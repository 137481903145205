.sliding-box {
    position: fixed;
    right: 0px;
    top: 120px;
    height: 380px;
    width: calc(100% - 150px);
    display: flex;
    flex-direction: row;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .14), 0 12px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .14);
    
    .handle {
        height: 100%;
        width: 30px;
        background-color: #ba1471;
        color: #ffffff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .box {
        flex-grow: 1;
        width: 100%;
        position: relative;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* .box::-webkit-scrollbar {
        display: none; // Safari and Chrome
    } */
}

.sliding-box-opened {
    background-color: #ffffff;
    transition: right 1.2s;
    right: calc(180px - 100%);
}

.sliding-box-closed {
    background-color: #ffffff;
    transition: right .7s;
    right: 0px;
}
