@import "../../variable.scss";
@import "../../mixins.scss";
.hash-table-cls {
  @include sarge-content;
  .title {
    display: block;
    background: $table-header;
    color: $title-color;
    text-align: left;
    padding: 2px 0;
    font-size: 16px;
    font-family: "Roboto Medium", sans-serif;
    border-bottom: 1px solid $heading-border;
  }
  .table {
    .content {
      tr {
        height: auto;
        &:nth-child(odd) {
          background: $white;
        }

        &:nth-child(even) {
          background: $table-row-alt;
        }
      }

      td {
        padding: 2px;
        font-family: "Roboto Regular", sans-serif;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
      .no-data-table-cls {
        td:last-child {
          text-align: center;
          padding: 5px;
        }
      }
    }
  }
}
