.header-main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.title {
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px
}

.tooltip-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
}

.span-star {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 2px;
  border: 1px solid #ffffff;
}

