.addcomment-box-cls {
    margin: 10px 10px 12px 8px;
    text-align: left;
    height: calc(100% - 25px);

    h4 {
        font-weight: 600;
    }

    h5 {
        font-weight: 600;
        color: gray;
    }

    textarea {
        border: none;
        background-color: #f9f9f9;
        resize: none;
    }

    .comment-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
    }

    .toc-list {
        width: 100%;
        flex-direction: row;
        margin: 1px 0px;

        .toc-selector {
            div:last-child {
                div {
                    max-height: 200px;
                }
            }
        }

        padding-bottom: 1rem;
    }

    .actions {
        height: 40px;
        position: absolute;
        bottom: 0;
        width: calc(100% - 50px);
        display: flex;
        align-items: center;
        margin: 10px 30px;
    }

    .overflow {
        overflow-y: auto;
        position: relative;
    }

    .save {
        margin-right: 8px;
    }

    .cancel {
        margin-right: auto;
    }

    .save-msg {
        font-size: 14px;
        color: #03b075;
        margin: auto;
    }

    .heading {
        text-align: left;
        font-weight: bold;
        margin: 10px 0;

        &.last {
            page-break-before: always;
        }
    }

    .submit-review,
    .submit-without-comment,
    .submit {
        margin-left: auto;
        margin-right: 1rem;
    }

    .mpe-comment-history {
        padding-top: 0.5rem;
        width: 50%;
    }

    .ds-button24 {
        text-align: left;
        align-items: start;
    }

    .mpe-comment-history svg {
        margin: 5px;
        width: 1.2rem;
        height: 1.2rem;
    }

    .comment-history-header {
        display: flex;
    }

    .comment-history-title {
        padding-top: 0.2rem;
    }
}