@keyframes smooth-appear {
    to {
        //bottom: 20px;
        opacity: 1;
    }
}

@keyframes smooth-disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.toast-container-cls {
    position: fixed;
    bottom: 0;
    padding: 10px;
    z-index: 1;
    text-align: center;
    opacity: 0;
    border-radius: 10px;
    animation: smooth-appear 0.5s ease forwards;

    &.disappear {
        animation: smooth-disappear 0.5s ease forwards;
    }

    .banner-cls {
        gap: 1rem;
    }
}
