.error-cls{
  height: 100%;
  span {
    display: inline-block;
    line-height: normal;
    text-align: center;
  }
  .header {
    background-color: #e4002b;
    height: 40px;
    color: #fff;
    font-weight: bold;
    padding: 10px;
  }
  .content {
    background-color: #fce6ea;
    height: calc(100% - 40px);
    padding: 30px;
  }
}
