  .report-wizard {
    text-align: center;
    background: white;
    width: 100%;
  }

  .report-wizard-button {
    border: none;
    color: white;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-top: 20px;
    width: 200px;

    &:disabled {
      background-color: #dcdcdc;
      cursor: not-allowed;
    }
  }

  .button-preview {
    background-color: #4caf50;
  }
