@import '../../mixins.scss';

.pdf-renderer-cls {
  height: 45rem;
  margin: 0 auto;
  padding: 5px 0;
  padding-left: 5%;
  padding-right: 5%;
  
  
  margin-bottom: 55px;

}