.data-table-cell-cls {
  @media print{
    min-width: 6rem;
    max-width: 10rem;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
    text-align: center;
    white-space: pre-line;
  }

  .data-table-cell-link {
    font-size: 0.9rem;
    padding-top: 0px;
    padding-bottom: 0px;
    text-decoration: none;
    padding-right: 0px;
    float: right;
  }
}
