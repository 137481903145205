@import "../../variable.scss";
@import "../../mixins.scss";
.table-component-cls {
  @include sarge-content;
  &.grayedBackground {
    opacity: 0.7;
    th,
    td {
      background-color: $disabled-gray !important;
    }
  }
  .table {
    background-color: $table-header;
    .table-head {
      border-bottom: 1px solid $heading-border;
      clip-path: none;
      tr {
        height: auto;
        box-shadow: unset;
        font-weight: 500;
        font-size: 1rem;
        tr:nth-child(even),
        tr:nth-child(odd) {
          background: $table-header;
        }
      }
      th {
        color: $title-color;
        padding: 0;
        font-size: 18px;
        font-family: "Roboto Medium", sans-serif;
        line-height: 1.5em;
        vertical-align: middle;
      }
    }
    tbody {
      clip-path: none;
      tr:nth-child(odd) {
        background: $white;
      }
      tr:nth-child(even) {
        background: $table-row-alt;
      }
      td {
        font-family: "Roboto Regular", sans-serif;
      }
      tr.no-data {
        td {
          text-align: center;
        }
      }
      .editable-column {max-width: 50px !important;}
    }
  }
}

.table-edit {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 26px;
  button {
    &.edit-btn,
    &.preview-btn,
    &.reset-btn {
      color: $section-heading;
    }
  }
}

.table-modify {
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  button {
    margin-bottom: 20px;
    padding: 0;
    margin-right: 8px;
    &.preview-btn,
    &.reset-btn {
      color: $section-heading;
    }
  }
  .edit-btn {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: "Roboto Medium", sans-serif;
    letter-spacing: 0px;
    line-height: 24px;
    width: 130px;
    height: 32px;
    background: rgb(218, 24, 132);
    border-radius: 3px;
    border: none;
    padding: 10px;
    font-weight: 500;

    &.padding-paste-add{
      width: 10rem;
    }
  }
}

.task-review-buttons{
  display: flex;
  flex-direction: row ;
  align-self: center;
  gap: 0.75rem;
  padding-top: 2rem;
  button {
    margin-bottom: unset;
    padding: unset;
    margin-right: unset;
    &:hover{
      background: $hover-section-heading;
    }
}}


.form-table-container {
  display: flex;
  padding-bottom: 12px;
  width: 100%;
  flex-direction: column;

  .row-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .columns-labels {
    display: flex;
    flex: 1 1 0;
    justify-content: center;
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }
}

.popup-dialog {
  min-height: 50px;
}

.dialog-edit {
  button {
    &.edit-btn,
    &.preview-btn,
    &.reset-btn {
      color: $section-heading;
    }
  }
  min-height: 200px !important;
  // max-height: 590px !important;
  .dialog-header {
    // padding: 5px 0px 0px 10px;
    font-weight: bold;
  }
  .date-picker {
    width: 72%;
  }
  .remarks-typo {
    margin-top: 20px;
  }
  .dialog-action {
    padding: 5px;
  }
  .action-main {
    margin-right: 3%;
    margin-bottom: 2%;
  }
  .ip-remarks {
    width: 100%;
    resize: none;
    margin-bottom: 10px;
  }
  .additional-fields-container {
    display: flex;
    flex-direction: column; /* Stack fields vertically */
  }

  .st-checkbox,
  .si-checkbox {
    padding: 2px;
  }

  .ip-textinput {
    border: solid rgb(202, 229, 245) 1px;
    width: 98%;
    margin: 2px;
  }

  .ip-textinput-disabled {
    border: solid rgb(229, 245, 255) 1px;
    background-color: rgb(227, 235, 240);
    width: 98%;
    margin: 2px;
  }
}

.ror-th-btn {
  left: 60px;
  border: none;
}


.table-container{
  @media screen {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  min-height: 30rem;
  margin-bottom:1rem
  }

}

.multiselection-dropdown{
  padding: 0.5rem 0;
  gap: 0.2rem;
  min-width: 30%;
  width: fit-content;
  button {
    margin : 0rem 0.25rem
    }
  .multi-select-pn{
    width: 100%;
    align-self: flex-start;
}
  .message-multi-select-pn{
    padding-bottom: 0.5rem;
    font-weight: bold;
    text-align: left;
    }

  .loading-div-multi{
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
