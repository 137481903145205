.title-data-cls{
  min-height: calc(210mm - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  .texts {
    display: flex;
    flex-flow: column;
    margin-left: 90px;
    text-align: left;

    h3 {
      font-weight: bold;
      margin: 0;
      padding: 5px 0;
      white-space: pre-line;
    }
  }

  .subtitle-container {
    padding: 15px 15px 0px 0px;
  }
  .subtitle-item {
    margin-bottom: 10px;
    font-size: large;
    font-weight: 600;
    filter: opacity(.85);
  }
  .small-step{
    position: absolute;
    margin-top: -10px;
    height: 10px;
    width: 30%;
    background: #00205b;
  }

  .footer-container{
    height: 150px;
    color: #ffffff;
    background: #00205b;
    position: relative;
    margin-top: 25px;
    bottom: 0;
    .footer {
      padding: 30px 0 0 90px;
      text-align: left;
      display: flex;
      flex-direction: row;
      height: 100%;
      background-color: #00205b;
      .signatures {
        flex: 5;
        flex-direction: column;
        div {
          // margin: 5px 0;
          display: flex;
          margin-bottom: 2px;
          span {
            margin-right: 15px;
          }
        }
      }
      .logo{
        height: 30px;
        width: 160px;
        align-self: flex-end;
        background-repeat: no-repeat;
        margin-right: 30px;
        margin-bottom: 25px;
      }
    }
  }
}
