@import '../../variable.scss';
@import '../../mixins.scss';

.report-section-cls {
  position: relative;
  &.report-hidden-cls{
    display: none
  }
  .text-style {
    display: flex;
    align-items: center;
    background-color: $section-heading;
    height: 30px;
    color: $white;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    padding-left: 18px;
    position: relative;
    line-height: 1.3;

    .menu {
      padding: 1px 7px;
      position: absolute;
      right: 0px;
      background: #647592;
      color: white;
      opacity: 1;
      height: 30px;
      display: flex;
      align-items: center;
      &:hover {
        background: #7989a4;
        cursor: pointer;
      }
    }
  }

  .section-root {
    position: relative;
    overflow: hidden;

    &:not(.first-section) {
      align-items: center;

      .section-top {
        display: flex;
        flex-flow: row;
        padding: 0 20px;
      }

      .section-main {
        display: flex;
        flex-flow: row;
        padding: 10px 20px 0 20px;

        .section-middle {
          padding: 0 10px;
        }

        .section-left {
          padding: 0 5px;
        }

        .section-right {
          padding: 0 5px;
        }
      }
    }
  }

  .report-content-cls {
    &:not(.first-section) {
      margin-bottom: 10px;
    }
  }
}

.secondary-interval {
  .text-style {
    background-color: $section-heading-secondary;
  }
}

.ternary-interval {
  .text-style {
    background-color: $section-heading-tertiary;
  }
}
