.view-comment-cls {
  padding: 0 25px;

  .heading {
    text-align: left;
    font-weight: bold;
    margin: 16px 0;

    &.last {
      page-break-before: always;
    }
  }

  .paragraph {
    text-align: left;
    font-size: 15px;
    white-space: pre-wrap;
    padding: 10px 10px;
    margin: 0 0 12px 0;
    background-color: #f7f9fa
  }

  p {
    margin-bottom: 0;
  }

  .comment-card {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    padding-bottom: 0;
  }

  .comment-desc {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0;
    padding: 0.5rem;
    width: 97%;
    overflow-wrap: break-word;
  }

  .comment-date {
    float: right;
  }

  .mpe-comment-history {
    padding-top: 0.5rem;
    width: 50%;
  }

  .ds-button24 {
    text-align: left;
    align-items: start;
  }

  .mpe-comment-history svg {
    margin: 5px;
    width: 1.2rem;
    height: 1.2rem;
  }

  .comment-history-header {
    display: flex;
  }

  .comment-history-title {
    padding-top: 0.2rem;
  }
}