@media print {
  html, body {
    .exclude-print {
      display: none;
    }

    section {
      margin: 0;
      min-height: auto;
      padding: 0;
      page-break-before: always;
    }
  }
}

.show-page-break {
  html, body {
    .exclude-print {
      display: none;
    }
    tr {
      &.exclude-screen {
        display: table-row !important;
      }
    }
    .exclude-screen {
      display: unset;
      td{
        display: table-cell !important;
      }
    }
    .page-ruler {
      border-bottom: 1px dashed black;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 710px;
    }
    section {
      margin: 0;
      min-height: auto;
      padding: 0;
      page-break-before: always;
    }

    .toggle-style{
      display: none;
    }

    .save-te-btn-cls, .pagebreak-btn-cls{
      display: inline-flex;
    }
    .hide-page-break-icon-cls{
      color: #da1884;
    }

    .edit-btn,.preview-btn,.restore-btn {
      position: relative;
    }
  }
}

@media screen {
  html {
    background: #f8f9fb;
  }
  html, body {
    section {
      margin: 10px 0;
      min-height: calc(210mm - 100px);
      box-shadow: 0 2px 10px #c5c6c7;
      &.last-section {
        margin: 10px 0 65px 0;
      }
    }
    .App {
      min-height: 100vh;
    }
    .exclude-screen {
      display: none;
    }
  }
}
