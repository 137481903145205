.footer-cls {
  background-color: #da1884;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  height: 55px;
  width: 100%;
  padding: 10px;
  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.button-label {
  display: inline;
  color: white;
  left: 10px;
  position: absolute;
}
