@import './print.scss';
@import './variable.scss';
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  &.main-cls{
    color: rgb(0, 0, 0);
    line-height: 1.3;
    h1, h2, h3, h4, h5, h6, .h1-li{
      line-height: 1.3;
      margin-bottom: 0.35rem;
    }
    h3{
      font-size: 1.625rem;
      color: $default;
    }
    h4{
      color:#2e3237;
      font-size: 1.25rem; 
    }
    h5{
      font-size: 1rem; 
    }
  }
  .code {
    font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New',
      monospace;
  }
  div#root {
    width: 100%;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
