@import '../../variable.scss';
.table-content-cls {
  .render-section-div {
    align-items: center;
    &:first-child {
      padding-right: 5px;
      text-align: left  ;
    }
    ul{
      line-height: 1;
      color: $default;
      font-size: 1rem;
      li{
        padding: 10px 16px;
      }
    }
  }

}
